<script>
  import { onMount } from 'svelte';

  import Boat from './components/Boat.svelte';
  import BoatSelect from './components/BoatSelect.svelte';
  import Compare from './components/Compare.svelte';
  import CustomPlot from './components/CustomPlot.svelte';
  import X41Comparison from './components/X41Comparison.svelte';  // Nuevo componente importado

  export let route = 'boat';
  export let sailnumber = null;

  // A route is custom if it starts with one of the route prefixes.
  const isCustomRoute = (value) => ['extremes', 'customplot', 'compare', 'x41-comparison'].some((item) => value.startsWith(item));

  function onhashchange() {
    const hash = window.location.hash;
    route = hash.length > 1 ? hash.substring(1) : 'ESP/ITA4149';

    if (isCustomRoute(route)) {
      sailnumber = null;
    } else {
      sailnumber = route;
      route = 'boat';
    }
  }

  onMount(() => {
    window.addEventListener('hashchange', onhashchange, false);
    onhashchange();
    return () => window.removeEventListener('hashchange', onhashchange, false);
  });

  $: {
    if (sailnumber && !isCustomRoute(sailnumber)) {
      window.location.hash = sailnumber;
    }
  }
</script>

<style>
  .navbar-brand img {
    width: 100px; /* Ajusta el ancho de la imagen */
  }
</style>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#"><img src="public/img/logo.png" alt="Logo" /></a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon" />
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        {#if !sailnumber || (sailnumber && !sailnumber.startsWith('compare'))}
          <li class="nav-item d-block-md">
            <BoatSelect bind:sailnumber />
          </li>
        {/if}
        <li class="nav-item"><a href="#compare" class="nav-link">Compare boats</a></li>
        <li class="nav-item"><a href="#x41-comparison" class="nav-link">X-41 Comparison</a></li>  <!-- Nuevo enlace agregado -->
      </ul>
      &nbsp;
    </div>
  </div>
</nav>

{#if route == 'extremes'}
  <Extremes bind:sailnumber />
{:else if route == 'customplot'}
  <CustomPlot />
{:else if route.startsWith('compare')}
  <Compare />
{:else if route.startsWith('x41-comparison')}
  <X41Comparison />  <!-- Nuevo componente agregado -->
{:else}
  <Boat {sailnumber} />
{/if}

