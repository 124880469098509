import App from './App.svelte';

const hash = window.location.hash;
const app = new App({
    target: document.querySelector('#container'),
    props: {
      sailnumber: 'ESP/ITA4149',
    },
});

export default app;
