<script>
    import { onMount } from 'svelte';
    import { writable } from 'svelte/store';
    import BoatSelect from './BoatSelect.svelte';
    import CompareBoat from './CompareBoat.svelte';

    const SEPARATOR = '|';
    const DEFAULT_BOAT = 'ESP/ITA4149';

    let sailnumbers = writable([DEFAULT_BOAT]);

    // Función para obtener datos de barcos desde la URL
    function getBoatsFromUrl() {
        const params = new URLSearchParams(window.location.search);
        const boats = params.get('boats');
        return boats ? boats.split(SEPARATOR).map(decodeURIComponent) : [];
    }

    onMount(() => {
        const boatsFromUrl = getBoatsFromUrl();
        sailnumbers.set([...new Set([DEFAULT_BOAT, ...boatsFromUrl])]);
    });

    // Función para actualizar los parámetros de la URL
    function updateUrl(sailnumbers) {
        const params = new URLSearchParams(window.location.search);
        const encodedBoats = sailnumbers.map(encodeURIComponent).join(SEPARATOR);
        params.set('boats', encodedBoats);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    }

    function addBoat() {
        sailnumbers.update(numbers => {
            const updatedNumbers = [...new Set([...numbers, ''])];
            updateUrl(updatedNumbers);
            return updatedNumbers;
        });
    }

    function removeBoat(index) {
        sailnumbers.update(numbers => {
            const updatedNumbers = numbers.filter((_, i) => i !== index);
            if (!updatedNumbers.includes(DEFAULT_BOAT)) {
                updatedNumbers.unshift(DEFAULT_BOAT);
            }
            updateUrl(updatedNumbers);
            return updatedNumbers;
        });
    }
</script>

<style>
    .container {
        padding: 20px;
    }
    .add-button {
        margin-top: 10px;
        font-size: 0.8em;
        padding: 5px 10px;
    }
    .row {
        display: flex;
        align-items: flex-start;
    }
    .column {
        flex: 30%;
        padding: 10px;
        max-width: 300px;
    }
    .comparison-column {
        flex: 70%;
        padding: 10px;
    }
    .remove-button {
        background-color: transparent;
        border: none;
        color: red;
        font-size: 1.5em;
        cursor: pointer;
        margin-left: 10px;
    }
</style>

<div class="container">
    <h1>Select Boats for Comparison</h1>
    <div class="row">
        <div class="column">
            <!-- Lista de seleccionadores de barcos -->
            {#each $sailnumbers as sailnumber, index}
                <div class="p-2 align-items-center d-flex">
                    <BoatSelect bind:sailnumber={$sailnumbers[index]} />
                    {#if $sailnumbers[index] !== DEFAULT_BOAT}
                        <button class="remove-button" on:click={() => removeBoat(index)}>&times;</button>
                    {/if}
                </div>
            {/each}
            <!-- Botón para añadir un nuevo barco -->
            <div class="p-2">
                <button class="add-button btn btn-primary" on:click={addBoat}>Add Boat</button>
            </div>
        </div>

        <div class="comparison-column">
            <!-- Mostrar el componente de comparación -->
            <CompareBoat boats={$sailnumbers} />
        </div>
    </div>
</div>

