<script>
  import { onMount } from 'svelte';
  import { indexLoader, getBoat } from '../api.js';

  let boats = [];
  let x41Boats = [];
  let error = null;
  let sortColumn = 'sailnumber'; // Columna por la cual ordenar inicialmente
  let sortDirection = 'asc'; // Dirección de ordenación inicial

  // Función para cargar los datos de los barcos
  const loadData = async () => {
    try {
      boats = await indexLoader();
      console.log("Datos cargados:", boats);

      // Filtrar los barcos de tipo X-41 exactamente
      const filteredBoats = boats.filter(boat => {
        const type = boat.type ? boat.type.toUpperCase() : '';
        return type === 'X-41';
      });

      // Cargar detalles de cada barco X-41
      x41Boats = await Promise.all(filteredBoats.map(async boat => {
        const detailedBoat = await getBoat(boat.sailnumber);
        return detailedBoat;
      }));
      console.log("Detalles de barcos X-41:", x41Boats);

      sortBoats(); // Ordenar los barcos después de cargarlos
    } catch (e) {
      error = "Error cargando datos: " + e.message;
      console.error(error);
    }
  };

  // Ordenar los barcos por la columna especificada
  const sortBoats = () => {
    console.log(`Ordenando por: ${sortColumn} en dirección: ${sortDirection}`);
    x41Boats = x41Boats.sort((a, b) => {
      const aValue = getNestedValue(a, sortColumn);
      const bValue = getNestedValue(b, sortColumn);
      
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
      } else {
        return sortDirection === 'asc' 
          ? aValue > bValue ? 1 : -1 
          : aValue < bValue ? 1 : -1;
      }
    });
    console.log("Barcos ordenados:", x41Boats);
  };

  // Obtener el valor anidado basado en la columna de ordenación
  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((o, p) => o ? o[p] : null, obj);
  };

  // Manejar el clic en el encabezado de la tabla para ordenar
  const handleSort = (column) => {
    console.log(`Clic en columna: ${column}`);
    if (sortColumn === column) {
      sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      sortColumn = column;
      sortDirection = 'asc';
    }
    sortBoats();
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }


  onMount(() => {
    loadData();
  });
</script>

<style>
  /* Add your styles here */
  th {
    cursor: pointer;
  }
</style>

<h1>Comparison of X-41 Models</h1>
{#if error}
  <p>{error}</p>
{:else}
  <table class="table table-bordered">
    <thead>
      <tr>
        <th on:click={() => handleSort('sailnumber')}>Sail Number</th>
        <th on:click={() => handleSort('name')}>Name</th>
        <th on:click={() => handleSort('type')}>Type</th>
        <th on:click={() => handleSort('certificateDate')}>Cert. Date</th>
        <th on:click={() => handleSort('rating.aptod')}>APH ToD</th>
        <th on:click={() => handleSort('rating.aptot')}>APH ToT</th>
        <th on:click={() => handleSort('rating.wltod')}>W/L ToD</th>
        <th on:click={() => handleSort('rating.wltot')}>W/L ToT</th>
	<!-- <th>CDL</th>
	<th>DA</th>
	<th>AA</th>
	<th>Crew</th>
	<th>Draft</th>
	<th>Displacement</th>
	<th>Wetted area</th>
	<th>IMS L</th>
	<th>LSM0</th>
	<th>SINK</th>
	<th>VCGD</th>
	<th>VCGM</th>
	<th>VCGM</th>
	<th>RM</th>
	<th>Lim</th>
	<th>St Ind</th> -->
      </tr>
    </thead>
    <tbody>
      {#each x41Boats as boat (boat.sailnumber)}
        <tr>
          <td>{boat.sailnumber}</td>
          <td>{boat.name}</td>
          <td>{boat.boat.type}</td>
	  <td>{formatDate(boat.certificateDate)}</td>
	  <td>{boat.rating.aptod}</td>
	  <td>{boat.rating.aptot}</td>
	  <td>{boat.rating.wltod}</td>
	  <td>{boat.rating.wltot}</td>
        </tr>
      {/each}
    </tbody>
  </table>
{/if}
