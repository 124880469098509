<script>
    import { onMount } from 'svelte';
    import { getBoat } from '../api.js';
    import { writable, derived } from 'svelte/store';

    export let boats = [];
    let boatDetails = writable([]);
    let activeTab = writable(0);
    let refBoat = null;

    let selectedDistance = writable(5);  // Distancia seleccionada por defecto 5

    async function fetchBoatDetails() {
        const details = await Promise.all(boats.map(async boatId => {
            if (!boatId) return null;
            try {
                const data = await getBoat(boatId);
                console.log(`Data for boat ${boatId}:`, data); // Mensaje de depuración
                if (boatId === 'ESP/ITA4149') {
                    refBoat = data;
                }
                return data;
            } catch (error) {
                console.error(`Error fetching data for boat ${boatId}:`, error);
                return { id: boatId, name: 'Unknown', error: true }; // Fallback data
            }
        }));
        const sortedDetails = details.filter(detail => detail !== null).sort((a, b) => {
            if (!a.rating || !b.rating) return 0;
            return a.rating.aptod - b.rating.aptod;
        });
        boatDetails.set(sortedDetails);
        console.log('Boat details:', sortedDetails); // Mensaje de depuración
    }

    // Función para calcular la diferencia en formato 00:00:00
    function calculateDifference(referenceWL, wl, distance) {
        const diffSeconds = (wl - referenceWL) * distance;
        const sign = diffSeconds >= 0 ? '' : '-';
        const absDiffSeconds = Math.abs(diffSeconds);
        const hours = Math.floor(absDiffSeconds / 3600);
        const minutes = Math.floor((absDiffSeconds % 3600) / 60);
        const seconds = Math.floor(absDiffSeconds % 60);
        return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    onMount(fetchBoatDetails);

    $: if (boats.length > 0) {
        fetchBoatDetails();
    }

    // Recalcular detalles del barco cuando cambian las distancias o los detalles del barco
    const recalculatedBoatDetails = derived(
        [boatDetails, selectedDistance],
        ([$boatDetails, $selectedDistance]) => {
            if (!refBoat) {
                console.warn('Reference boat not loaded yet'); // Mensaje de advertencia
                return $boatDetails;
            }
            return $boatDetails.map(boat => {
                return {
                    ...boat,
                    relativeTimes: boat.vpp.wl.map((wl, i) => calculateDifference(refBoat.vpp.wl[i], wl, $selectedDistance))
                };
            });
        }
    );
</script>

<style>
    .table-container {
        display: none;
    }
    .table-container.active {
        display: block;
    }
    .positive {
        color: red;
    }
    .negative {
        color: green;
    }
</style>

<div class="table-container active">
    <h3>ToD - Windward/Leeward</h3>
    <label>
        Distancia:
        <input type="number" bind:value={$selectedDistance} min="0" step="1" class="form-control form-control-sm" />
    </label>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>Yacht Name</th>
                <th>Sail No</th>
                <th>Type</th>
                <th>APH</th>
                {#each ['6kts', '8kts', '10kts', '12kts', '14kts', '16kts', '20kts', '24kts'] as speed}
                    <th>{speed}</th>
                {/each}
            </tr>
        </thead>
        <tbody>
            {#each $recalculatedBoatDetails as boat}
            <tr>
                <td>{boat.name}</td>
                <td>{boat.sailnumber}</td>
                <td>{boat.boat.type}</td>
                <td>{boat.rating ? boat.rating.aptod : 'N/A'}</td>
                {#each boat.relativeTimes as time}
                    <td class={time.startsWith('-') ? 'negative' : 'positive'}>{time}</td>
                {/each}
            </tr>
            {/each}
        </tbody>
    </table>
</div>

